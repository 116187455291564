import React from 'react';

const Footer = () => {
  return (
    <footer id="footer" className="bg-primaryBlack text-primaryWhite py-12">
      <div className="container mx-auto px-4">
        {/* Top section with company info, quick links, and contact details */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-12">
          {/* Company Info */}
          <div>
            <h2 className="text-3xl font-bold mb-4">Prasad Agri Export</h2>
            <p className="text-primaryGray">
              Delivering premium quality agricultural products to customers
              around the globe, with a focus on sustainability and excellence.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-xl font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="#about"
                  className="text-primaryGray hover:text-primaryGreen"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="#services"
                  className="text-primaryGray hover:text-primaryGreen"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  className="text-primaryGray hover:text-primaryGreen"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Details */}
          <div>
            <h3 className="text-xl font-bold mb-4">Contact Us</h3>
            <ul className="space-y-2">
              <li className="text-primaryGray">Phone: +91 99795 24900</li>
              <li className="text-primaryGray">
                Email: prasadagriexport@gmail.com
              </li>
              <li className="text-primaryGray">
                Address: Universal Township Behinde, Police Station, 36, Halvad,
                Gujarat 363330
              </li>
            </ul>
          </div>

          {/* Social Media */}
          <div>
            <h3 className="text-xl font-bold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="#" className="text-primaryGray hover:text-primaryGreen">
                Facebook
              </a>
              <a href="#" className="text-primaryGray hover:text-primaryGreen">
                Twitter
              </a>
              <a href="#" className="text-primaryGray hover:text-primaryGreen">
                LinkedIn
              </a>
            </div>
          </div>
        </div>

        {/* Divider */}
        <hr className="border-primaryGray mb-6" />

        {/* Bottom section */}
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p className="text-primaryGray text-sm">
            © 2024 Prasad Agri Export. All rights reserved.
          </p>
          <ul className="flex space-x-4 text-sm text-primaryGray">
            <li>
              <a href="#" className="hover:text-primaryGreen">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-primaryGreen">
                Terms of Service
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-primaryGreen">
                Sitemap
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

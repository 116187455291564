import React from 'react';
import { FaBox, FaShoppingBag, FaLeaf, FaWeightHanging } from 'react-icons/fa';

// Import images directly
import doubleParrotImg from '../assets/images/double_parrot.jpeg';
import singleParrotImg from '../assets/images/single_parrot.jpeg';
import scooterQualityImg from '../assets/images/scooter_split.jpeg';
import scooterPlus from '../assets/images/scooter_plus.jpeg';
import EegaleQuality from '../assets/images/eagle_quality.jpeg';
import EaglePlusSplit from '../assets/images/eagle_plus_split.jpeg';
import Extra1 from '../assets/images/extra-one.jpg';
import dhanadal from '../assets/images/dhanadal.jpg';

// List of services with details and image imports
const services = [
  {
    title: 'Double Parrot',
    packing: '40kg & 30kg available',
    bag: 'Window pp bag & Bopp bag',
    cleaning: 'Machine Clean ( doubble distoner) & Sortex clean',
    netWeight: '40kg & 30kg',
    image: doubleParrotImg, // Use imported image
  },
  {
    title: 'Single Parrot',
    packing: '40kg & 30kg available',
    bag: 'Window pp bag & Bopp bag',
    cleaning: 'Machine Clean ( doubble distoner) & Sortex clean',
    netWeight: '40kg & 30kg',
    image: singleParrotImg,
  },
  {
    title: 'Scooter + Quality',
    packing: '40kg & 30kg available',
    bag: 'Window pp bag & Bopp bag',
    cleaning: 'Machine Clean ( doubble distoner) & Sortex clean',
    netWeight: '40kg & 30kg',
    image: scooterPlus,
  },
  {
    title: 'Scooter + Split',
    packing: '40kg & 30kg available',
    bag: 'Window pp bag & Bopp bag',
    cleaning: 'Machine Clean ( doubble distoner) & Sortex clean',
    netWeight: '40kg & 30kg',
    image: scooterQualityImg,
  },

  {
    title: 'Eagle + Quality',
    packing: '40kg & 30kg available',
    bag: 'Window pp bag & Bopp bag',
    cleaning: 'Machine Clean ( doubble distoner) & Sortex clean',
    netWeight: '40kg & 30kg',
    image: EegaleQuality,
  },
  {
    title: 'Eagle + Split',
    packing: '40kg & 30kg available',
    bag: 'Window pp bag & Bopp bag',
    cleaning: 'Machine Clean ( doubble distoner) & Sortex clean',
    netWeight: '40kg & 30kg',
    image: EaglePlusSplit,
  },
  {
    title: 'Dhanadal',
    packing: '40kg & 30kg available',
    bag: 'Window pp bag & Bopp bag',
    cleaning: 'Machine Clean ( doubble distoner) & Sortex clean',
    netWeight: '40kg & 30kg',
    image: dhanadal,
  },
];

const Services = () => {
  return (
    <section id="services" className="py-20 bg-white">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold text-primaryBlack mb-8">
          Our Products
        </h2>
        <p className="text-lg text-primaryGray mb-12">
          We offer a range of high-quality agricultural products with consistent
          packaging and cleaning options.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="relative bg-white p-6 rounded-lg shadow-md transition-transform transform hover:scale-105 overflow-hidden"
            >
              <img
                src={service.image}
                alt={service.title}
                className="h-48 w-full object-cover rounded-lg mb-4"
              />
              {/* Overlay for product name on hover */}
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 p-4">
                <h3 className="text-xl font-semibold text-white text-center">
                  {service.title}
                </h3>
              </div>

              {/* Always visible card details */}
              <div className="mt-4 space-y-2">
                <h3 className="text-xl font-semibold text-primaryBlack truncate">
                  {service.title}
                </h3>

                <div className="text-primaryGray text-sm flex flex-col space-y-1">
                  <div className="flex items-start">
                    <FaBox className="mr-2 text-primaryGreen mt-1" />
                    <span>
                      <strong className="mr-1">Packing:</strong>{' '}
                      {service.packing}
                    </span>
                  </div>

                  <div className="flex items-start">
                    <FaShoppingBag className="mr-2 text-primaryGreen mt-1" />
                    <span>
                      <strong className="mr-1">Bag Type:</strong> {service.bag}
                    </span>
                  </div>

                  <div className="flex items-start">
                    <FaWeightHanging className="mr-2 text-primaryGreen mt-1" />
                    <span>
                      <strong className="mr-1">Net Weight:</strong>{' '}
                      {service.netWeight}
                    </span>
                  </div>

                  <div style={{ marginTop: 11, textAlign: 'center' }}>
                    <span>{service.cleaning}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
